import React, { useState, useEffect } from 'react';
import { Table, Loader, Message } from 'semantic-ui-react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../../firebase/config';

interface ViewAttendanceProps {
  moduleId: string;
  students: {
    id: string;
    firstName: string;
    lastName: string;
    initiatedName?: string;
  }[];
}

interface AttendanceRecord {
  date: Date;
  attendees: Record<string, boolean>;
}

const ViewAttendance: React.FC<ViewAttendanceProps> = ({ moduleId, students }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [attendanceRecords, setAttendanceRecords] = useState<AttendanceRecord[]>([]);

  useEffect(() => {
    const fetchAttendanceRecords = async () => {
      try {
        const q = query(
          collection(db, `modules/${moduleId}/attendance`), 
          orderBy('date', 'asc')
        );
        const querySnapshot = await getDocs(q);
        
        const records: AttendanceRecord[] = querySnapshot.docs.map(doc => ({
          date: doc.data().date.toDate(),
          attendees: doc.data().attendees
        }));

        setAttendanceRecords(records);
      } catch (err) {
        console.error('Error fetching attendance:', err);
        setError('Failed to load attendance records');
      } finally {
        setLoading(false);
      }
    };

    fetchAttendanceRecords();
  }, [moduleId]);

  if (loading) return <Loader active>Loading attendance records...</Loader>;
  if (error) return <Message negative>{error}</Message>;

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });
  };

  const calculateTotalPresent = (studentId: string) => {
    return attendanceRecords.reduce((total, record) => {
      return total + (record.attendees[studentId] ? 1 : 0);
    }, 0);
  };

  // Sort students by first name
  const sortedStudents = [...students].sort((a, b) => 
    a.firstName.localeCompare(b.firstName)
  );

  return (
    <Table celled structured>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell rowSpan={2}>S.No</Table.HeaderCell>
          <Table.HeaderCell rowSpan={2}>Student Name</Table.HeaderCell>
          {attendanceRecords.map(record => (
            <Table.HeaderCell key={record.date.toISOString()}>
              {formatDate(record.date)}
            </Table.HeaderCell>
          ))}
          <Table.HeaderCell rowSpan={2}>Total Present</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {sortedStudents.map((student, index) => (
          <Table.Row key={student.id}>
            <Table.Cell textAlign="center">{index + 1}</Table.Cell>
            <Table.Cell>
              {student.initiatedName || `${student.firstName} ${student.lastName}`}
            </Table.Cell>
            {attendanceRecords.map(record => (
              <Table.Cell 
                key={`${student.id}-${record.date.toISOString()}`}
                positive={record.attendees[student.id]}
                negative={!record.attendees[student.id]}
                textAlign="center"
              >
                {record.attendees[student.id] ? '✓' : '✗'}
              </Table.Cell>
            ))}
            <Table.Cell textAlign="center">
              {calculateTotalPresent(student.id)}/{attendanceRecords.length}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default ViewAttendance; 