import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Table, 
  Button, 
  Checkbox, 
  Message, 
  Loader, 
  Grid, 
  Form, 
  Icon 
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { 
  collection, 
  doc, 
  setDoc, 
  getDoc, 
  getDocs, 
  query, 
  orderBy,
  Timestamp 
} from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase/config';
import "react-datepicker/dist/react-datepicker.css";

interface MarkAttendanceProps {
  moduleId: string;
  students: {
    id: string;
    firstName: string;
    lastName: string;
    initiatedName?: string;
  }[];
}

interface AttendanceRecord {
  moduleId: string;
  date: Timestamp;
  attendees: Record<string, boolean>;
  updatedBy: string;
  updatedAt: Timestamp;
}

const MarkAttendance: React.FC<MarkAttendanceProps> = ({ moduleId, students }) => {
  const [user] = useAuthState(auth);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [attendees, setAttendees] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState<{ type: 'success' | 'error', content: string } | null>(null);
  const [lastUpdate, setLastUpdate] = useState<{ by: string; at: Date } | null>(null);
  const [updaterDetails, setUpdaterDetails] = useState<{
    initiatedName?: string;
    firstName?: string;
    lastName?: string;
  } | null>(null);

  useEffect(() => {
    if (selectedDate) {
      fetchAttendance(selectedDate);
    }
  }, [selectedDate]);

  const fetchAttendance = async (date: Date) => {
    const dateString = date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-');

    try {
      const attendanceDocRef = doc(db, `modules/${moduleId}/attendance`, dateString);
      const attendanceDocSnap = await getDoc(attendanceDocRef);
      
      if (attendanceDocSnap.exists()) {
        const data = attendanceDocSnap.data() as AttendanceRecord;
        setAttendees(data.attendees);
        setLastUpdate({
          by: data.updatedBy,
          at: data.updatedAt.toDate()
        });
        
        // Fetch updater details
        const updaterDoc = await getDoc(doc(db, 'users', data.updatedBy));
        if (updaterDoc.exists()) {
          const updaterData = updaterDoc.data();
          setUpdaterDetails({
            initiatedName: updaterData.initiatedName,
            firstName: updaterData.firstName,
            lastName: updaterData.lastName
          });
        }
      } else {
        // Initialize attendees with all false (absent) if no record exists
        const initialAttendees: Record<string, boolean> = {};
        students.forEach(student => {
          initialAttendees[student.id] = false;
        });
        setAttendees(initialAttendees);
        setLastUpdate(null);
        setUpdaterDetails(null);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching attendance:', error);
      setMessage({ type: 'error', content: 'Failed to fetch attendance record' });
      setLoading(false);
    }
  };

  const handleAttendanceChange = (studentId: string, isPresent: boolean) => {
    setAttendees(prev => ({
      ...prev,
      [studentId]: isPresent
    }));
  };

  const handleSaveAttendance = async () => {
    if (!user) return;

    setSaving(true);
    setMessage(null);
    const dateString = selectedDate.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-');

    try {
      const attendanceDocRef = doc(db, `modules/${moduleId}/attendance`, dateString);
      
      // Create attendance record for all students
      const completeAttendees: Record<string, boolean> = {};
      students.forEach(student => {
        completeAttendees[student.id] = attendees[student.id] || false;
      });

      const attendanceData: AttendanceRecord = {
        moduleId,
        date: Timestamp.fromDate(selectedDate),
        attendees: completeAttendees,
        updatedBy: user.uid,
        updatedAt: Timestamp.fromDate(new Date())
      };

      await setDoc(attendanceDocRef, attendanceData);

      setMessage({ type: 'success', content: 'Attendance saved successfully' });
      setLastUpdate({
        by: attendanceData.updatedBy,
        at: attendanceData.updatedAt.toDate()
      });

      // Fetch and set updater details
      const updaterDoc = await getDoc(doc(db, 'users', user.uid));
      if (updaterDoc.exists()) {
        const updaterData = updaterDoc.data();
        setUpdaterDetails({
          initiatedName: updaterData.initiatedName,
          firstName: updaterData.firstName,
          lastName: updaterData.lastName
        });
      }
    } catch (error) {
      console.error('Error saving attendance:', error);
      setMessage({ type: 'error', content: 'Failed to save attendance' });
    } finally {
      setSaving(false);
    }
  };

  // Sort students by first name
  const sortedStudents = [...students].sort((a, b) => 
    a.firstName.localeCompare(b.firstName)
  );

  const customDatePickerStyle = {
    input: {
      padding: '9px 14px',
      borderRadius: '4px',
      border: '1px solid rgba(34, 36, 38, 0.15)',
      width: '100%',
      fontSize: '1em'
    }
  };

  if (loading) return <Loader active>Loading...</Loader>;

  return (
    <Container>
      <Grid stackable style={{ marginBottom: '2em' }}>
        <Grid.Row>
          <Grid.Column width={6}>
            <Form>
              <Form.Field>
                <label>Select Date</label>
                <div style={customDatePickerStyle.input}>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date: Date | null) => date && setSelectedDate(date)}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    placeholderText="Select Date"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    isClearable
                    className="date-picker-input"
                  />
                </div>
              </Form.Field>
            </Form>
          </Grid.Column>
          <Grid.Column width={10}>
            {lastUpdate && (
              <Message info icon>
                <Icon name='info circle' />
                <Message.Content>
                  <Message.Header>Last Update</Message.Header>
                  Updated by {updaterDetails?.initiatedName 
                    ? updaterDetails.initiatedName
                    : updaterDetails?.firstName && updaterDetails?.lastName 
                      ? `${updaterDetails.firstName} ${updaterDetails.lastName}`
                      : 'Unknown'} at {lastUpdate.at.toLocaleString()}
                </Message.Content>
              </Message>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {message && (
        <Message
          positive={message.type === 'success'}
          negative={message.type === 'error'}
          icon
        >
          <Icon name={message.type === 'success' ? 'check circle' : 'times circle'} />
          <Message.Content>{message.content}</Message.Content>
        </Message>
      )}

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>S.No</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Present</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {sortedStudents.map((student, index) => (
            <Table.Row key={student.id}>
              <Table.Cell>{index + 1}</Table.Cell>
              <Table.Cell>
                {student.initiatedName || `${student.firstName} ${student.lastName}`}
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Checkbox
                  toggle
                  checked={attendees[student.id] || false}
                  onChange={(_, data) => handleAttendanceChange(student.id, !!data.checked)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Button
        primary
        size="large"
        onClick={handleSaveAttendance}
        loading={saving}
        disabled={saving}
        style={{ marginTop: '2em' }}
      >
        <Icon name="save" />
        Save Attendance
      </Button>
    </Container>
  );
};

export default MarkAttendance; 