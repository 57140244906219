import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../components/Home';
import SignUp from '../Authentication/SignUp';
import Login from '../Authentication/Login';
import ForgotPassword from '../Authentication/ForgotPassword';
import Dashboard from '../components/Dashboard/Dashboard';
import EditProfile from '../Authentication/EditProfile';
import Team from '../components/Team';
import TeamMember from '../components/TeamMember';
import ChangePassword from '../Authentication/ChangePassword';
import ProtectedRoute from './ProtectedRoute';
import TeacherAdminStaffRoute from './TeacherAdminStaffRoute';
import ModuleHome from '../components/Module/ModuleHome';
import CreateModule from '../components/Module/CreateModule';
import AdminTeacherRoute from './AdminTeacherRoute';
import ModulesDashboard from '../components/Module/ModulesDashboard';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/edit-profile" element={<ProtectedRoute><EditProfile /></ProtectedRoute>} />
      <Route path="/modules" element={<ProtectedRoute><ModulesDashboard /></ProtectedRoute>} />
      <Route path="/modules/create-module" element={
        <ProtectedRoute>
          <AdminTeacherRoute>
            <CreateModule />
          </AdminTeacherRoute>
        </ProtectedRoute>
      } />
      <Route path="/modules/:moduleId" element={<ProtectedRoute><ModuleHome /></ProtectedRoute>} />
      <Route path="/dashboard" element={
        <ProtectedRoute>
          <TeacherAdminStaffRoute>
            <Dashboard />
          </TeacherAdminStaffRoute>
        </ProtectedRoute>
      } />
      <Route path="/team" element={<ProtectedRoute><Team /></ProtectedRoute>} />
      <Route path="/team/:uid" element={<ProtectedRoute><TeamMember /></ProtectedRoute>} />
    </Routes>
  );
};

export default AppRoutes;