import React, { useState } from 'react';
import { Menu, Image, Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.jpg';
import ProfileMenu from './ProfileMenu';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/config';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useRecoilValue } from 'recoil';
import { userState } from '../recoil/authAtom';

const Navigation: React.FC = () => {
  const [user] = useAuthState(auth);
  const [userData, setUserData] = React.useState<any>(null);

  React.useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.uid) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      }
    };
    fetchUserData();
  }, [user]);

  const menuStyle = {
    background: 'white',
    height: '120px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    border: 'none',
  };

  const containerStyle = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  };

  const menuItemStyle = {
    fontSize: '1.25em', // Increase font size by 25%
    border: 'none',
  };

  const logoStyle = {
    width: '50px', // Adjust this value as needed
    height: 'auto',
    marginRight: '1em',
  };

  return (
    <Menu fixed="top" style={menuStyle}>
      <Container style={containerStyle}>
        <Menu.Item as={Link} to="/" header style={menuItemStyle}>
          <Image src={logo} alt="BVA Logo" style={logoStyle} />
          Bhaktivedanta Vedic Academy
        </Menu.Item>
        <Menu.Item as={Link} to="/" style={menuItemStyle}>Home</Menu.Item>
        <Menu.Item as={Link} to="/team" style={menuItemStyle}>Team</Menu.Item>
        <Menu.Item as={Link} to="/modules" style={menuItemStyle}>Modules</Menu.Item>
        {user && (userData?.role === 'admin' || userData?.role === 'teacher') && (
          <Menu.Item as={Link} to="/dashboard" style={menuItemStyle}>
            Dashboard
          </Menu.Item>
        )}
        {/* <Menu.Item as={Link} to="/about" style={menuItemStyle}>About</Menu.Item> */}
        <Menu.Menu position="right">
          {user ? (
            <>
              <Menu.Item style={menuItemStyle}>
                {userData ? (
                  userData.initiatedName ? (
                    `Welcome ${userData.initiatedName}`
                  ) : (
                    `Welcome ${userData.firstName} ${userData.lastName}`
                  )
                ) : (
                  'Loading...'
                )}
              </Menu.Item>
              <ProfileMenu />
            </>
          ) : (
            <>
              <Menu.Item as={Link} to="/login" style={menuItemStyle}>Login</Menu.Item>
              {/* <Menu.Item as={Link} to="/signup" style={menuItemStyle}>Sign Up</Menu.Item> */}
            </>
          )}
        </Menu.Menu>
      </Container>
    </Menu>
  );
};

export default Navigation;